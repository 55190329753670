<template>
  <div>
    <b-card class="mb-2">
      <div class="d-flex justify-content-between">
        <h1 class="my-auto">
          Liste des notifications des nouvelles demandes d'inscriptions des prometteurs d'établissements
        </h1>
        <!-- <b-button
          class="mt-1 float-right"
          variant="primary"
          @click="$router.push({name:''})"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Ajouter un établissment</span>
        </b-button> -->

      </div>
    </b-card>

    <!-- <b-card> -->
    <!-- <b-table
        responsive
        :items="filteredTableData"
        :fields="tableColumns"
        show-empty
        empty-text="Aucun resultats"
        :busy="isLoadingTableData"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #cell(id)="data">
          <strong>{{ ((paginationData.perPage * (paginationData.currentPage-1))|| 0) + data.index + 1 }}</strong>
        </template>
        <template #cell(ref)="data">
          <b-avatar variant="dark">
            <feather-icon icon="ImageIcon" />
          </b-avatar>{{ '  ' }}
          {{ data.item.ref }}
        </template>
        <template #cell(statut)="data">
          <b-badge
            v-if="data.item.isVerified"
            variant="success"
          >
            Validé
          </b-badge>
          <b-badge
            v-if="!data.item.isVerified"
            variant="warning"
          >
            En attente
          </b-badge>
          <b-badge
            v-if="data.item.isRejected"
            variant="danger"
          >
            Rejetté
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-button
            variant="primary"
            class="mx-0"
            @click="
              $router.push({
                name: 'notifications-details',
                params: {
                  notificationId: data.item._id,
                },
              })
            "
          >
            <feather-icon icon="EyeIcon" />
            <span class="text-nowrap">Détails</span>
          </b-button>
        </template>
      </b-table> -->
    <!-- <div
        v-if="tableData && tableData.length > 1"
        class="d-flex justify-content-between mt-1"
      > -->
    <!-- <div class="d-flex flex-row">
          <span class="my-auto mr-1">Afficher </span>
          <div>
            <b-form-select
              v-model="paginationData.perPage"
              :options="optionsPerPage"
              size="sm"
              class="form-control col"
            />
          </div>
          <span class="my-auto ml-1">lignes </span>
        </div> -->

    <!-- <div>
          <b-pagination
            v-model="paginationData.currentPage"
            :total-rows="paginationData.total"
            :per-page="paginationData.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div> -->

    <!-- <div class="my-auto"> -->
    <!-- <div class="d-flex flex-row">  -->
    <!-- <span class="my-auto">Affichage de {{ paginationData.metaData.from }} à {{ paginationData.metaData.to }} sur {{ paginationData.total }} </span> -->
    <!-- </div> -->
    <!-- </div> -->
    <!-- </div> -->
    <!-- </b-card> -->

    <div v-if="filteredTableData.length > 0">
      <b-card
        v-for="notification in filteredTableData"
        :key="notification.id"
      >
        <div>
          <h4>{{ notification.title }}</h4>
        </div>
        <div>
          {{ notification.text }}
        </div>
      </b-card>
    </div>
    <div v-else>
      <b-card>
        <h4>Vous n'avez aucune notification</h4>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCard,
  BBadge,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import utilsService from '@/services/utils/utils.service'

// store module and vuex utilities
import notificationsStroreModule from "@/store/notifications";
import paramsStoreModule from "@/store/params";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "notifications", module: notificationsStroreModule },
      { path: "params", module: paramsStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules};
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },  
  name: 'ArticleList',
  components: {
    BFormCheckbox,
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCard,
    BBadge,
  },
  data() {
    return {
      filters: { options: {} },
      currentPage: 1,
      perPage: 10,
      rows: 100,
      tableData: null,
      filteredTableData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      // tableColumns: [
      //   { key: 'id', label: '#', sortable: false },
      //   { key: 'nomNotification', label: 'Nom', sortable: false },
      //   { key: 'nomNotification', label: 'Prenom', sortable: false },
      //   {
      //     key: 'emailNotification',
      //     label: 'Email',
      //     sortable: false,
      //   },
      //   {
      //     key: 'telephoneNotification',
      //     label: 'Téléphone',
      //     sortable: false,
      //   },
      //   {
      //     key: 'statut',
      //     label: 'statut inscription',
      //     sortable: false,
      //   },

      //   'actions',
      // ],
      isLoadingTableData: false,

      query: {
        options: {},
        filter: {
          // text: [],
          // type: [],
        },
        // page: this.curPage - 1,
        // perPage: this.perPage
      },

    }
  },
  computed: {
    ...mapGetters('params', {
      typesRoles: 'getter_typesRoles',
    }),
    totalRows() {
      return (this.tableData && this.tableData.length) || 0
    },
  },
  watch: {
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(this.tableData.slice(0, val - 1))
        }
      },
    },
    tableData: {
      immediate: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData)
        }
      },
    },
    $route: {
      immediate: true,
      async handler(val, old) {
        // await this.action_fetchTypesRoles()
        this.getListNotifications()
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.getListNotifications()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.getListNotifications()
      },
    },
  },
  mounted() {
    // this.getListNotifications()
  },
  methods: {
    ...mapActions('notifications', { action_fetchNotifications: 'fetchNotifications' }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),

    async getListNotifications() {
      this.filteredTableData = []
      this.isLoadingTableData = true
      // const userRole = this.typesRoles.find(
      //   e => e.nom === 'Agent Etablissement',
      // )
      // // console.log(userRole)
      // const userRoleId = userRole._id

      const {
        currentUserId,
      } = utilsService.currentUserUtils()
      // console.log('currentUserId : ', currentUserId)

      this.query.options = {
        utilisateur: currentUserId,
        // utilisateur: '62f0ce859a4c343cbf7205fd',
      }
      const params = {
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
        ...this.query,
      }
      // console.log('params : ', params)

      await this.action_fetchNotifications({params:{...params,} || null,},)
        .then(response => {
          // console.log('response : ', response)
          // this.tableData = response.data.notifications
          // // console.log('notifications-list ----------------------', response.data.notifications, )
          // response.data.notifications.forEach(element => {
            let element = response.data.notifications[0]
            // console.log('element **************$: ', element)
            if(element['formCreate'] && element['formCreate'].length > 0){
              // console.log("element['formCreate']: ", element['formCreate'])
            }
            if(element['formEdit'] && element['formEdit'].length > 0){
              // console.log("element['formEdit']: ", element['formEdit'])
            }
            if(element['newPreInscription'] && element['newPreInscription'].length > 0){
              // console.log("element['newPreInscription']: ", element['newPreInscription'])
              // this.filteredTableData.concat(element['newPreInscription']);
              for(let i = 0; i < element['newPreInscription'].length; i++){
                // console.log("element['newPreInscription'][i]: ", element['newPreInscription'][i])
                this.filteredTableData.push( element['newPreInscription'][i] )
              }
            }
            if(element['newAgent'] && element['newAgent'].length > 0){
              // console.log("element['newAgent']: ", element['newAgent'])
            }
          // });

          // console.log("================== this.filteredTableData: ================ ", this.filteredTableData)

          // this.paginationData.total = response.data.total
          // this.isLoadingTableData = false

          // if (this.paginationData.metaData.from == 0) this.paginationData.metaData.from += 1
          // else this.paginationData.metaData.from = (this.paginationData.perPage * this.paginationData.currentPage) - (this.paginationData.perPage)
          // this.paginationData.metaData.to = (this.paginationData.perPage * this.paginationData.currentPage) - this.paginationData.perPage + this.tableData.length
        })
        .catch(err => {
          this.isLoadingTableData = false
          // console.log('error-notifications-list', err)
        })

      // setTimeout(() => {
      //   this.tableData = new Array(200)
      //     .fill({
      //       nom: 'Nom',
      //       prenom: 'Prenom',
      //       email: 'user@user.com',
      //     })
      //     .map((e, i) => {
      //       const temp = JSON.parse(JSON.stringify(e))
      //       return { ...temp, id: i }
      //     })

      //   this.isLoadingTableData = false
      // }, 2000)
    },
  },
}
</script>
<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;
  a {
    text-transform: uppercase;
  }
}
.footer,
.footer .per-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
