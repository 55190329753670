import { BaseService } from './base.service'

export class NotificationsService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  // eslint-disable-next-line no-unused-vars

  fetchNotifications({ params }) {
    return new Promise((resolve, reject) => {
      // console.log('paramsBeforeSending : ', params)
      this.axiosInstance
        .get('/notifications/list', { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  getNotification(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/notifications/${id}`, {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // getAgent(id) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .get(`/admins/${id}`, {})
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }

  getListTypesNotification() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/types-notifications/list', {})
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createNotification(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/notifications/create-file', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  verifyNotificationEmail(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/admins/confirm-email', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  validateInscriptionNotification(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/admins/validate-preinscription', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  rejectInscriptionNotification(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/admins/reject-preinscription', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  updateNotification({ id, data }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/notifications/${id}`, data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // createAgent(data) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .post('/inscriptions/register-agent', data)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }

  // archiverAgent(data) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .post('', data)
  //       .then(response => resolve(response))
  //       .catch(error => reject(error))
  //   })
  // }
}

export default new NotificationsService()
