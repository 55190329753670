import notificationsService from '@/services/http/notifications.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getListTypesNotification({ rootState }, data) {
      const res = await notificationsService.getListTypesNotification(data)
      return res
    },
    async getNotification({ rootState }, data) {
      const res = await notificationsService.getNotification(data)
      return res
    },
    async fetchNotifications({ rootState }, data) {
      const res = await notificationsService.fetchNotifications(data)
      return res
    },
    async createNotification({ rootState }, data) {
      const res = await notificationsService.createNotification(data)
      return res
    },
    async verifyNotificationEmail({ rootState }, data) {
      const res = await notificationsService.verifyNotificationEmail(data)
      return res
    },
    async validateInscriptionNotification({ rootState }, data) {
      const res = await notificationsService.validateInscriptionNotification(data)
      return res
    },
    async rejectInscriptionNotification({ rootState }, data) {
      const res = await notificationsService.rejectInscriptionNotification(data)
      return res
    },
    async updateNotification({ rootState }, data) {
      const res = await notificationsService.updateNotification(data)
      return res
    },
    // async createAgent({ rootState }, data) {
    //   const res = await notificationsService.createAgent(data)
    //   return res
    // },
    // async getAgent({ rootState }, data) {
    //   const res = await notificationsService.getAgent(data)
    //   return res
    // },
    // async archiverAgent({ rootState }, data) {
    //   const res = await notificationsService.archiverAgent(data)
    //   return res
    // },
  },
}
